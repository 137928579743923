<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col">
        <div class="page-title p-2 mb-6">
          <div class="vx-col w-full flex items-center data-list-btn-container">
            <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="relative vx-row m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
              v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
              <div class="text-sm">{{$t(tab)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <shipblu-table
      :sst="true"
      pagination
      orders
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :max-items="maximumItems"
      :data="supplies"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="flex flex-wrap items-center gap-3 whitespace-nowrap">
          <export-supplies />
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Request Date')}}</shipblu-th>
        <shipblu-th>{{$t('Supplies')}}: {{$t('Quantity')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].id" class="text-left sm:order-3 order-25 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].id }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].created">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Requested')}}</p>
            <p class="sm:text-base text-sm">{{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ new Date(data[indextr].created).toLocaleTimeString('en-GB') }}</p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].created">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Supplies: QTY')}}</p>
            <div v-for="item in tr.supply_request_items" :key="item.index">
              <span class="sm:text-base text-sm" v-if="item.count > 0 && item.package_size">{{ item.package_size.name.split('(')[0] }}: {{ item.count }} </span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].merchant.name }}</p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Phone Number')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].merchant.store_phone }}</p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].zone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zone')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].pickup_point ? $t(data[indextr].pickup_point.address.zone.name) : $t(data[indextr].merchant.address.zone.name) }}</p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].address">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Address')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].pickup_point ? data[indextr].pickup_point.address.line_1 + ', ' + data[indextr].pickup_point.address.line_2 : data[indextr].merchant.address.line_1 + ', ' + data[indextr].merchant.address.line_2 }}</p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <template>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
              <vx-tooltip position="left" :text="data[indextr].supply_request_items.map(item => item.package_size && item.package_size.short_code != 'OSZ' ? `${item.package_size.short_code}: ${item.count_delivered}` : '').filter(element => element !== '').join('\n')">
                <div class="flex" style="width: fit-content;">
                  <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </vx-tooltip>
            </template>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                  v-if="$store.state.AppActiveUser.userRole !== 'admin'"
                    :disabled="data[indextr].status === 'delivered' || (data[indextr].status === 'requested' && data[indextr].supplies_price.total > 0)"
                    @click="editQuantity(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="CheckCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Actual Quantity')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    :disabled="data[indextr].status === 'delivered'"
                    @click="updateSupply(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="CheckCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delivered')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <edit-supplies-count :supplyModal="supplyModal" :data="supply" @supplyModal="supplyModal = $event" @loadSupplies="loadSupplies"></edit-supplies-count>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import EditSuppliesCount from '../headOfFleet/components/EditSuppliesCount.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportSupplies from './components/ExportSupplies.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      tabs: ['Requests', 'All'],
      tabsDic: {'0': 'Requests', '1': 'All'},
      tabsNameDic: {'Requests': 0, 'All': 1},
      activeTab: 0,
      common,
      selected: [],
      supplies: [],
      supplyModal: false,
      type: '',
      supply: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      packagesSize: [],
      packagesDic: {},
      deliveredSupply: {},
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.currentPage = 1
      this.loadSupplies()
    },
    activeTab () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-supplies`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadSupplies()
    },
    '$route.params.warehouseID' () {
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.currentPage = 1
      this.loadPackagesSize()
      this.loadSupplies()
    }
  },
  methods: {
    isCreditCard (rowData) {
      return rowData.payment_requests.filter(element => element.status === 'paid')
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadSupplies()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadSuppliesSearch)
    },
    loadSuppliesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadSupplies()
    },
    editQuantity (supply) {
      this.supplyModal = true
      this.supply = supply
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    loadPackagesSize () {
      sendRequest(false, false, this, 'api/v1/package-sizes/', 'get', null, true,
        (response) => {
          const data = response.data
          const time = new Date()
          const PackageSizesDataTime = {data, time}
          localStorage.setItem('packages', JSON.stringify(PackageSizesDataTime))
          this.managePackageSize(response.data)
        }
      )
    },
    managePackageSize (packages) {
      this.packagesSize = packages
      this.packagesSize.forEach(packageSize => {
        this.packagesDic[packageSize.id] = packageSize.short_code
      })
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadSupplies () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&warehouse=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}${(this.activeTab === 0 || this.activeTab === '0') ? '&status=created,requested,out_for_delivery,delivery_attempted' : ''}`
      sendRequest(true, false, this, `api/v1/supplies/?${query}`, 'get', null, true,
        (response) => {
          this.supplies = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    updateSupply (data) {
      this.deliveredSupply = data
      this.$vs.dialog({
        color: 'success',
        title: 'Confirm',
        text: 'Are you sure to delivered supply?',
        accept: this.confirmDeliveredSupply
      })
    },
    confirmDeliveredSupply () {
      sendRequest(false, false, this, `api/v1/supplies/${this.deliveredSupply.id}/`, 'patch', {status: 'delivered'}, true,
        () => {
          this.loadSupplies()
        }
      )
    }
  },
  components: {
    EditSuppliesCount,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportSupplies,
    ShipbluPagination
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1
    if (localStorage.getItem('packages') && (new Date() - new Date(JSON.parse(localStorage.getItem('packages')).time)) / (60 * 60 * 1000) <= 1) {
      this.managePackageSize(JSON.parse(localStorage.getItem('packages')).data)
    } else {
      this.loadPackagesSize()
    }
    if (this.$route.query.tab) this.loadSupplies()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
